.LoginPageContainer {
  background-color: silver;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.HomePageButtons {
  padding: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.HomePage {
  background-color: #2acf2a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.image-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  position: relative;
}

.add-button {
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1; /* Ensure the button is on top of the image */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: silver;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #00ff00;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.active-scroll-spy {
  position: relative;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  color: black;
}

.active-scroll-spy::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 7px;
}
